import React, { useState, useEffect } from 'react';
import './Gallery.css'; // Make sure to create and import the CSS file

const API_BASE_URL = 'https://gothos.biz/api';

const WalletAddress = ({ address }) => {
  const [copied, setCopied] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(address).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    });
  };

  return (
    <span className="wallet-address-container">
      <span
        className="wallet-address"
        onClick={handleClick}
        title="Click to copy full address"
      >
        {address.slice(0, 6)}...{address.slice(-4)}
        <span className="copy-icon">📋</span>
      </span>
      {copied && <span className="copy-feedback">Copied!</span>}
    </span>
  );
};

const Gallery = ({ selectedWallet }) => {
  const [galleryItems, setGalleryItems] = useState([]);
  const [newItem, setNewItem] = useState({ title: '', description: '', image_url: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchGalleryItems();
  }, []);

  const fetchGalleryItems = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/gallery`);
      if (!response.ok) {
        throw new Error('Failed to fetch gallery items');
      }
      const data = await response.json();
      setGalleryItems(data);
    } catch (error) {
      setError('Error fetching gallery items: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addGalleryItem = async (e) => {
    e.preventDefault();
    if (!selectedWallet) {
      alert('Please select a wallet first');
      return;
    }
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/gallery`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          ...newItem,
          wallet_address: selectedWallet.address
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to add gallery item');
      }
      await response.json();
      fetchGalleryItems();
      setNewItem({ title: '', description: '', image_url: '' });
    } catch (error) {
      setError('Error adding gallery item: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteGalleryItem = async (id) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${API_BASE_URL}/gallery/${id}`, { method: 'DELETE' });
      if (!response.ok) {
        throw new Error('Failed to delete gallery item');
      }
      fetchGalleryItems();
    } catch (error) {
      setError('Error deleting gallery item: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="gallery-container">
      <h2>Gallery</h2>
      <form onSubmit={addGalleryItem} className="gallery-form">
        <input
          type="text"
          placeholder="Title"
          value={newItem.title}
          onChange={(e) => setNewItem({ ...newItem, title: e.target.value })}
          required
        />
        <input
          type="text"
          placeholder="Description"
          value={newItem.description}
          onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
        />
        <input
          type="text"
          placeholder="Image URL"
          value={newItem.image_url}
          onChange={(e) => setNewItem({ ...newItem, image_url: e.target.value })}
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Adding...' : 'Add Item'}
        </button>
      </form>

      {error && <div className="error-message">{error}</div>}

      <div className="gallery-items">
        {galleryItems.map((item) => (
          <div key={item.id} className="gallery-item">
            <img src={item.image_url} alt={item.title} className="gallery-image" />
            <div className="gallery-item-content">
              <h3 className="gallery-item-title">{item.title}</h3>
              <p className="gallery-item-description">{item.description}</p>
              <p className="gallery-item-wallet">
                Wallet: <WalletAddress address={item.wallet_address} />
              </p>
            </div>
            <button
              onClick={() => deleteGalleryItem(item.id)}
              disabled={isLoading}
              className="delete-button"
            >
              {isLoading ? 'Deleting...' : 'Delete'}
            </button>
          </div>
        ))}
      </div>

      {isLoading && <div className="loading-spinner">Loading...</div>}
    </div>
  );
};

export default Gallery;