import React, { useState, useEffect } from 'react';
import Gallery from "./Gallery";
import BuyGothosButton from "./BuyGothos";

const API_BASE_URL = 'https://gothos.biz/api';

const BlockchainDashboard = () => {
  const [message, setMessage] = useState('');


  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [amount, setAmount] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');
  const [data, setData] = useState('');
  const [blockchain, setBlockchain] = useState([]);

  useEffect(() => {
    fetchMessage();
    fetchWallets();
    fetchBlockchain();
  }, []);

  const fetchMessage = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}`);
      const data = await response.json();
      setMessage(data.message);
    } catch (error) {
      console.error('Error fetching message:', error);
    }
  };

  const fetchWallets = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/get_wallets`);
      const data = await response.json();
      setWallets(Object.entries(data).map(([address, wallet]) => ({ address, ...wallet })));
    } catch (error) {
      console.error('Error fetching wallets:', error);
    }
  };

  const fetchBlockchain = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/get_blockchain`);
      const data = await response.json();
      setBlockchain(data);
    } catch (error) {
      console.error('Error fetching blockchain:', error);
    }
  };

  const createNewWallet = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/new_wallet`, { method: 'POST' });
      await response.json();
      fetchWallets();
    } catch (error) {
      console.error('Error creating new wallet:', error);
    }
  };

  const addMoney = async () => {
    if (!selectedWallet || !amount) return;
    try {
      const response = await fetch(`${API_BASE_URL}/add_money`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ address: selectedWallet.address, amount: parseFloat(amount) }),
      });
      await response.json();
      fetchWallets();
      setAmount('');
    } catch (error) {
      console.error('Error adding money:', error);
    }
  };

  const sendData = async () => {
    if (!selectedWallet || !recipientAddress || !amount || !data) return;
    try {
      const response = await fetch(`${API_BASE_URL}/send_data`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sender_address: selectedWallet.address,
          recipient_address: recipientAddress,
          amount: parseFloat(amount),
          data: data,
        }),
      });
      await response.json();
      fetchWallets();
      fetchBlockchain();
      setRecipientAddress('');
      setAmount('');
      setData('');
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
        <h2>Blockchain Dashboard</h2>
        <BuyGothosButton/>
        <Gallery selectedWallet={selectedWallet} />
        <p>{message}</p>
      </div>

      <div style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
        <h2>Wallets</h2>
        <button onClick={createNewWallet}>Create New Wallet</button>
        <div style={{ marginTop: '10px' }}>
          {wallets.map((wallet) => (
            <div key={wallet.address} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
              <span>Address: {wallet.address}</span>
              <span>Balance: {wallet.balance}</span>
              <button onClick={() => setSelectedWallet(wallet)}>Select</button>
            </div>
          ))}
        </div>
      </div>

      {selectedWallet && (
        <div style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
          <h2>Selected Wallet: {selectedWallet.address}</h2>
          <div>
            <input
              type="number"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <button onClick={addMoney}>Add Money</button>
          </div>
          <div style={{ marginTop: '10px' }}>
            <input
              placeholder="Recipient Address"
              value={recipientAddress}
              onChange={(e) => setRecipientAddress(e.target.value)}
            />
            <input
              type="number"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <input
              placeholder="Data"
              value={data}
              onChange={(e) => setData(e.target.value)}
            />
            <button onClick={sendData}>Send Data</button>
          </div>
        </div>
      )}

      <div style={{ border: '1px solid #ccc', padding: '10px' }}>
        <h2>Blockchain</h2>
        <div>
          {blockchain.map((block, index) => (
            <div key={index} style={{ border: '1px solid #ddd', padding: '10px', marginBottom: '10px' }}>
              <p>Index: {block.index}</p>
              <p>Previous Hash: {block.previous_hash}</p>
              <p>Timestamp: {new Date(block.timestamp * 1000).toLocaleString()}</p>
              <p>Transactions: {JSON.stringify(block.transactions)}</p>
              <p>Hash: {block.hash}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlockchainDashboard;