import React from 'react';

const BuyGothosButton = () => {
  const handleBuyClick = () => {
    window.open('https://buy.stripe.com/test_fZe5l72B05ND5xe3cc', '_blank');
  };

  return (
    <button
      onClick={handleBuyClick}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    >
      Buy Gothos
    </button>
  );
};

export default BuyGothosButton;